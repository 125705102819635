const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://1oe16t5ux4.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://60f4y763f1.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://bq9my7j6hh.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.dev.droopy.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.droopy.forwoodsafety.com',
    WEBSOCKET: 'wss://uvluzxok9f.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;